<template>
  <div class="home">
    <div class="content">
      <!-- 空白占位盒子 -->
      <!-- <div class="top_empty"></div> -->
      <!-- 顶部 -->
      <div class="top">
        <img src="@/assets/images/home/logo.png" class="logo" alt="">
        <!-- 首页 -->
      </div>

      <!-- 轮播 -->
      <van-swipe :autoplay="3000" :loop="true" @change="onChange2">
        <van-swipe-item v-for="(item, index) in data.swiper" :key="index">
          <img :src="item.imgUrl" alt="" @click="toFoundationDetails(item)">
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <div class="box" v-for="(item, index) in data.swiper" :key="index"
              :class="index == data.current ? 'active' : ''">
            </div>
          </div>
        </template>
      </van-swipe>

      <!-- 我要捐赠物质申请按钮组 -->
      <!-- <div class="view2">
        <div class="pink" @click="toDonate">
          <img src="@/assets/images/home/wyjz.png" alt="">
          我要捐赠
        </div>
        <div class="blue" @click="toMaterialsApply">
          <img src="@/assets/images/home/wzsl.png" alt="">
          物资申领
        </div>
      </div> -->


      <!-- 捐赠项目 -->
      <div class="project">
        <div class="space_between align_items_start">
          <!-- 捐赠项目类目tab -->
          <div class="project_tab">
            <div class="active">
              公益项目
              <div class="line"></div>
            </div>
            <!-- <div class="" @click="toMaterialsApply">
              物资申领项目
              <div class="line"></div>
            </div> -->
          </div>
          <div class="space_between align_items_center" style="padding-top: .1333rem;color: #777;font-size: .36rem;"
            @click="toDonateList">
            <div>更多</div>
            <img src="@/assets/images/home/right.png" alt="" style="width: .16rem;margin-left: .0667rem;">
          </div>
        </div>

        <!-- 图片四宫格 -->
        <!-- <div class="project_img">
          <div v-for="(item, index) in data.projectList" :key="index" @click="toDonateDetails(item.id)">
            <img :src="item.cover" alt="">
          </div>
        </div> -->
        <div class="projlist">
          <div class="flex" v-for="(item, index) in data.projectList" :key="index" :class="index == 2 ? 'active' : ''">
            <img @click="toDonateDetails(item.id)" :src="item.cover" alt="">
            <div class="lir">
              <div>
                <div class="p1" @click="toDonateDetails(item.id)">{{ item.title }}</div>
                <div style="font-size: .28rem; color: #888;display: none;"><span style="margin-right: .2rem;">{{
                  item.bout
                    }}人捐款</span> 共{{ item.amount }}元</div>
              </div>

              <div class="space_between align_items_center">
                <div>

                  <div class="grslbtn" style="display: none;">{{ item.menu_id == 283 ? '专项基金' : item.menu_id == 285
                    ? '品牌项目' : '公益活动' }} </div>
                </div>

                <div class="slbtn" v-if="item.raise==1" @click="toDonate(item.id)">献爱心</div>
                <div class="slbtn" v-else @click="toDonateDetails(item.id)">献爱心</div>
              </div>

            </div>
          </div>
        </div>
        <!-- 捐赠信息 -->
        <div class="project_msg">
          <div class="ti">捐赠信息</div>
          <div class="msg_tab">
            <div v-for="(item, index) in data.donateData.years" :key="index" @click="yearChange(index)">
              <div class="box"
                :class="[data.yearIndex == index ? 'active' : '', index == 5 ?'last' : '']"
                v-if="index < 6">{{ item }}</div>
            </div>
          </div>

          <div>
            <div class="msg_item">
              <div>本年度善款募捐总额</div>
              <div class="space_between align_items_center"><span class="c3 fw6 f40 mr5">{{
                filteredTime(data.yearData.amount, 'amount')
                  }}</span><span class="c7">元</span></div>
            </div>
            <div class="msg_item">
              <div>本年度募资捐款人次</div>
              <div class="space_between align_items_center"><span class="c3 fw6 f40 mr5">{{
                filteredTime(data.yearData.frequency, 'frequency') }}</span><span class="c7">人</span></div>
            </div>
            <div class="msg_item">
              <div>本年度企业捐款总数</div>
              <div class="space_between align_items_center"><span class="c3 fw6 f40 mr5">{{
                filteredTime(data.yearData.enterprise, 'enterprise') }}</span><span class="c7">家</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>


<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted, computed } from "vue";
import Api from "../../utils/api";
import { useRouter, useRoute } from 'vue-router'
import axios from "axios";
import { openAppFunction } from '../../common/js/share'
const router = useRouter()
let data = reactive({
  swiper: [],
  current: 0,
  projectList: [],
  donateData: {},
  yearIndex: 0,
  yearData: {},
});

onMounted(() => {

  document.title = '职工基金会'
  //获取捐赠列表（图片列表
  getwelfareGetlist()
  //获取首页轮播图
  getNewsList()
  //获取捐赠信息
  foundateMsg()


  shareFn()

});

const shareFn = () => {
  const jsonString = {
    shareUrl: window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: '爱心互献',//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}


const onChange2 = (index) => {
  data.current = index;

};


// //我要捐赠跳转
// const toDonate = () => {
//   router.push({ path: '/donate' })
// };

//年度切换
const yearChange = (i) => {
  data.yearIndex = i
  data.yearData = data.donateData.list[data.donateData.years[i]]
};

//物质申领
const toMaterialsApply = () => {
  router.push({ path: '/materialsApply' })
};

//跳转捐赠项目详情
const toDonateDetails = (id) => {
  router.push({ path: '/donateDetails', query: { id: id } })
};

//跳转轮播项目详情
const toFoundationDetails = (item) => {
  if (item.linkUrl) {
    window.location.href = item.linkUrl
  } else {
    router.push({ path: '/foundationDetails', query: { id: item.id } })
  }
};

//跳转捐赠项目列表
const toDonateList = () => {
  sessionStorage.setItem('donateReturn', '首页跳转的项目列表')
  router.push({ path: '/donate/donateList' })
};

//我要捐赠页面
const toDonate = (id) => {

    router.push({ path: '/donate', query: { id: id } })

  
}

//获取首页轮播图
const getNewsList = () => {
  Api.articleViewChannel({ channelName: 'news', sum: 1 }).then((res) => {
    data.swiper = res.data
  });
};

//获取捐赠信息
const foundateMsg = () => {
  axios({
    method: "get",
    url: 'https://servers.china-wdf.org.cn/api/donate/check/stat',
  })
    .then((res) => {
      //console.log(res)
      data.donateData = res.data.data
      data.yearData = data.donateData.list[data.donateData.years[0]]
    })
};

//获取捐赠列表（图片列表
const getwelfareGetlist = () => {
  Api.welfareGetlist({ top: 1 }).then((res) => {
    if (res.status == 200) {
      data.projectList = res.data
      console.log(data.projectList)
    }
  });
};



const filteredTime = computed(() => (number, name) => {
	if (number == 0) return 0;
  const numberStr = name == 'amount' ? Number(number).toFixed(2) : Number(number).toFixed().replace(/^0+/, '')

  // 使用正则表达式将每三个数字分隔
  const formatted = numberStr.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
  // 如果小数部分为00，则去除小数点和零
  if (isNaN(numberStr)) {
    return 0
  } else {
    return formatted.replace(/,\d{2}$/, '');
  }

})
</script>
<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}

.top_empty {
  height: 1.2rem;
  background-color: #fff;
}

.home {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;

  .content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .projlist {
      margin-bottom: .4rem;

      >div {
        font-size: .3867rem;
        // border-bottom: .0133rem #ececec solid;
        padding: .2rem 0;
        position: relative;


        &:last-child {
          border: none;
        }

        .lir {
          height: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 3.9rem);
          position: relative;

          .p1 {
            width: 4.8rem;
          }
        }


        .grslbtn {
          height: .4133rem;
          width: 1.5733rem;
          background-color: #FFE200;
          color: #DE7F13;
          font-size: .28rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: .0533rem;
        }

        span {
          color: #ACACAC;
          font-size: .28rem;
          margin-right: .5067rem;
        }

        .slbtn {
          width: 1.5333rem;
          height: .6rem;
          background-color: #E61D18;
          border-radius: .0533rem;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .3rem;
        }



        img {
          width: 3.5rem;
          height: 2rem;
          border-radius: .1067rem;
          margin-right: .3333rem;
          object-fit: cover;
        }
      }

    }

    .project {
      width: 100%;
      padding: .4667rem .3067rem;
      background-color: #fff;

      .project_msg {
        .msg_item {
          height: 1.0667rem;
          border-top: .0133rem dotted #ccc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: .36rem;

          .f40 {
            font-size: .5333rem;
            color: #c0192b;
          }
        }

        .msg_tab {
          display: flex;
          justify-content: space-between;
          margin-bottom: .1733rem;

          >div {
            &:first-child {
              .box {
                border-top-left-radius: .1333rem;
                border-bottom-left-radius: .1333rem;
              }

            }

            .box {
              width: 1.54rem;
              height: .8267rem;
              background-color: #f5f5f5;
              text-align: center;
              line-height: 1;
              font-size: .36rem;
              font-weight: 600;
              color: #333;
              display: flex;
              justify-content: center;
              align-items: center;




            }

            .last {
              border-top-right-radius: .1333rem;
              border-bottom-right-radius: .1333rem;
            }

            .active {
              background-color: #E61D18;
              color: #fff;
            }
          }


        }

        .ti {
          font-weight: 600;
          font-size: .44rem;
          color: #333;
          margin-bottom: .1867rem;
        }
      }

      .project_img {
        margin-bottom: .1rem;

        >div {
          width: 4.5333rem;
          height: 2.56rem;
          display: inline-block;
          margin-bottom: .3067rem;

          &:nth-child(odd) {
            margin-right: .3067rem;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: .1067rem;

          }
        }
      }

      .project_tab {
        font-size: .36rem;
        display: flex;
        align-items: center;
        margin-bottom: .3067rem;

        >div {
          margin-right: .5067rem;
          color: #777777;
        }

        .line {
          margin-top: .1067rem;
          height: .1067rem;
          border-radius: 1.28rem;
        }

        .active {
          color: #333;
          font-size: .44rem;
          font-weight: 600;

          .line {

            background: linear-gradient(240deg, rgba(255, 255, 255, 0) 0%, #E61D18 100%);
          }
        }
      }
    }

    .top {
      height: 1.1333rem;
      width: 100%;
      background-color: #fff;
      font-size: .44rem;
      text-align: center;
      padding-top: .3333rem;
      box-sizing: border-box;
      position: relative;
    }

    .logo {
      height: .4667rem;
      position: absolute;
      left: .3067rem;
      top: .3333rem;
    }

    .view2 {
      height: 2.3333rem;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 0 .3333rem;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: .2667rem;

      >div {
        width: 4.5333rem;
        height: 1.5333rem;
        font-size: .46rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .1rem;

      }

      .pink {
        background: linear-gradient(180deg, #FFECEE 0%, #FFFFFF 100%);
        color: #F23E5D;
      }

      .blue {
        background: linear-gradient(180deg, #ECF0FF 0%, #FFFFFF 100%);
        color: #1346DE;
      }

      img {
        width: .9333rem;
        margin-right: .16rem;
      }
    }

    .van-swipe {
      height: 5.8rem;
      position: relative;

      img {
        width: 100%;
        height: 100%;

      }
    }

    .custom-indicator {

      position: absolute;
      bottom: .1333rem;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;

      .box {
        width: .1067rem;
        height: .1067rem;
        border-radius: .1067rem;
        background-color: #fff;
        margin-right: 3px;
      }

      .active {
        width: .2533rem;
      }
    }

    .main {
      margin-top: -30px;

      .van-tabs {
        padding: 0 20px 10px;
      }

      .main_bg {
        background-image: linear-gradient(#fff, #f5f5f5);
        padding: 10px 20px 0 20px;
        border-radius: 30px 30px 0 0;
        position: relative;

        .classify {
          padding: 20px 0;

          .small_classify {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;

            div {
              display: flex;
              justify-content: center;
              flex-flow: column;
              align-items: center;
              width: 20%;
            }

            .icon {
              width: 30px;
              height: 30px;
              margin: 10px;
            }
          }

          .big_classify {
            display: flex;

            div {
              flex: 1;
              display: flex;
              justify-content: center;
              flex-flow: column;
              align-items: center;

              .icon {
                width: 50px;
                height: 50px;
                margin-bottom: 5px;
              }
            }
          }
        }

        .search {
          position: relative;

          .search_text {
            position: absolute;
            background-color: #ffc400;
            right: -6px;
            top: 1px;
            width: 50px;
            height: 32px;
            border-radius: 16px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
          }

          input {
            width: 100%;
            border: 1px solid #ffc400;
            border-radius: 20px;
            height: 30px;
          }
        }
      }
    }


  }
}
</style>