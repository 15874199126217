
//请求接口方法集合
import {
    get,
    post

} from "./request.js";


export default {
    // 获取首页捐赠项目列表
    welfareGetlist(params) {
        return get('/client/welfare/getlist', params);
    },

    // 捐赠项目详情
    welfareInfo(params) {
        return get('/client/welfare/info', params);
    },

    // 轮播详情
    articleShow(params) {
        return get('/client/article/show/' + params);
    },

    // 赠项目详情
    materialproject(params) {
        return get('/client/materialproject/' + params);
    },

    //获取申领项目列表
    applyForProject(params) {
        return get('/client/apply/for/project', params);
    },

    // 物质申领详情
    applyForProjectDetails(params) {
        return get('/client/apply/for/project/' + params);
    },


    // 获取首页轮播图
    articleViewChannel(params) {
        return get('/client/article/view/channel/' + params.channelName + '/' + params.sum+'?tuijian=1');
    },

    //获取渠道列表
    channelsGetlist(params) {
        return get('/client/channels/getlist', params);
    },

    //领取物资
    applyUser(params) {
        return post('/client/apply/user/' + params.id, params);
    },

    //领取物资列表
    applyUserList(params) {
        return get('/client/apply/user', params);
    },


    //获取省市区
    clientArea() {
        return get('/client/area');
    },
    ///获取用户申领详情
    applyUserGet(params) {
        return get('/client/apply/user/get/' + params);
    },

    //捐赠微信支付
    wechatPay(params) {
        return post('/client/donateorder/h5pay', params);
    },

    //捐赠微信支付
    alipayPay(params) {
        return post('/client/donateorder/alipay', params);
    },

    //获取支付订单详情
    getDonateOrderDetail(params) {
        return get('/client/donateorder/getorder', params);
    },
    //获取uid
    getUid() {
        return get('/authorize/getUid');
    },
    //查询uid状态
    getUidStat(params) {
        return get('/authorize/getuidstat?uid=' + params.uid);
    },
    //上传卡包
    uploadCardPackage(params) {
        return get('/authorize/uploadyv?_did=' + params._did + '&name=' + params.name + '&amount=' + params.amount + '&date=' + params.date + '&id=' + params.id);
    },
    //查询是否加入卡包
    checkKa(params) {
        return get('/client/donateorder/checkCollect/' + params.id);
    },
    //获取openid
    getOpenIdHandler(params) {
        return post('client/weixin/get', params);
    },
    //是否已关注
    checkGZ(params) {
        return post('client/weixin/check', params);
    },
    //捐赠详情页  捐赠金额列表
    getDonateorderGetlist(params) {
        return get('client/donateorder/getlist?pageIndex='+params.pageIndex+'&pageSize='+params.pageSize+'&projectId='+params.projectId);
    }

};

