<template>
  <div class="materialsApply">
    <!-- 顶部 -->
    <Header :titleName="data.titleName" :return="false" />
    <div class="content">

      <!-- 搜索 -->
      <div class="search space_between align_items_center">
        <div class="align_items_center inpbox">
          <!-- <div>全国</div>
          <img class="down" src="@/assets/images/materialsApply/down.png" alt="">
          <div class="line"></div> -->
          <img class="sea_icon" src="@/assets/images/materialsApply/sea_icon.png" alt="">
          <van-field type="search" v-model="data.AFPdata.Keyword" placeholder="搜索项目名称" @blur="getApplyForProject()"
            @search="getApplyForProject()" />
          <!-- <input type="search"  v-model="data.AFPdata.Keyword" placeholder="搜索项目名称" > -->
        </div>
        <div class="space_between align_items_center" @click="filterShowchange(true)">
          <div>筛选</div>
          <img class="down" src="@/assets/images/materialsApply/down.png" alt="">
        </div>
      </div>
      <!-- 列表为空 -->
      <div class="apply_list_empty" v-if="data.list.length < 1 && !data.loading">
        <img src="@/assets/images/materialsApply/apply_list_empty.png" alt="">
        <div class="first_div">暂无申领项目</div>
        <div class="last_div">请刷新页面或稍后尝试~</div>
      </div>
      <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>
      <div class="list" v-if="!data.loading && data.list.length > 0" v-infinite-scroll="loadMore"
        infinite-scroll-distance="0">
        <div class="flex" v-for="(item, index) in data.list" :key="index" :class="index == 2 ? 'active' : ''">
          <img :src="item.imgUrl" alt="" @click="onChange(item)">
          <div class="lir">
            <div class="white-space1" style="width: 6rem;" @click="onChange(item)">{{ item.title }}</div>
            <div style="display: none;">
              <span>{{ item.minQuantity }}份物资</span>
              <span>{{ item.receiveNumber }}人申领</span>
            </div>
            <!--     <div class="grslbtn" >{{item.typeName}}</div>
            <div class="end" v-if="item.startStatus == 3">申领结束</div>
            <div class="time" v-else>
              <div class="r" v-if="item.startStatus == 2">距项目结束</div>
              <div class="r" v-if="item.startStatus == 1">距项目开始</div>
              
              <div class="d">{{filteredTime(item)}}天</div>
            </div> -->
            <div class="slbtn slbtn_end" v-if="item.startStatus == 3">申领结束</div>
            <div class="slbtn" v-else @click="onChange(item)"> 我要申领</div>
          </div>
        </div>
      </div>



      <!-- 筛选弹窗 -->
      <div class="jj_dialog" v-if="data.filterShow" @click="filterShowOff()">
        <div style="background-color: #fff;" @click.stop="">
          <!-- 顶部 -->
          <Header :titleName="'物资申领'" :return="false" />
          <!-- 搜索 -->
          <div class="search space_between align_items_center">
            <div class="align_items_center inpbox">
              <!-- <div>全国</div>
              <img class="down" src="@/assets/images/materialsApply/down.png" alt="">
              <div class="line"></div> -->
              <img class="sea_icon" src="@/assets/images/materialsApply/sea_icon.png" alt="">
              <van-field v-model="data.AFPdata.Keyword" placeholder="搜索项目名称" @search="getApplyForProject()" />
            </div>
            <div class="space_between align_items_center">
              <div>筛选</div>
              <img class="down" src="@/assets/images/materialsApply/down.png" alt="">
            </div>
          </div>

          <!-- 选项 -->
          <div class="slselect">
            <div class="slgroup">
              <div class="group_name">申领主体</div>
              <div class="diabtns align_items_center">
                <div :class="data.AFPdata.typeId == 0 ? 'active' : ''" @click="typeIdChange(0)">全部</div>
                <div :class="data.AFPdata.typeId == 1 ? 'active' : ''" @click="typeIdChange(1)">个人申领</div>
                <div :class="data.AFPdata.typeId == 2 ? 'active' : ''" @click="typeIdChange(2)">企业申领</div>
              </div>
            </div>
            <div class="slgroup">
              <div class="group_name">申领类型</div>
              <div class="diabtns align_items_center">
                <div :class="data.AFPdata.GoodType == 0 ? 'active' : ''" @click="GoodTypeChange(0)">全部</div>
                <div :class="data.AFPdata.GoodType == 1 ? 'active' : ''" @click="GoodTypeChange(1)">服务申领</div>
                <div :class="data.AFPdata.GoodType == 2 ? 'active' : ''" @click="GoodTypeChange(2)">实物申领</div>
                <div :class="data.AFPdata.GoodType == 3 ? 'active' : ''" @click="GoodTypeChange(3)">第三方申领</div>
              </div>
            </div>
            <div class="slgroup" style="margin-bottom: .8rem;">
              <div class="group_name">项目状态</div>
              <div class="diabtns align_items_center">
                <div :class="data.AFPdata.StartStatus == 0 ? 'active' : ''" @click="StartStatusChange(0)">全部</div>
                <div :class="data.AFPdata.StartStatus == 1 ? 'active' : ''" @click="StartStatusChange(1)">未开始</div>
                <div :class="data.AFPdata.StartStatus == 2 ? 'active' : ''" @click="StartStatusChange(2)">进行中</div>
                <div :class="data.AFPdata.StartStatus == 3 ? 'active' : ''" @click="StartStatusChange(3)">已结束</div>
              </div>
            </div>

            <!-- 按钮 -->
            <div class="flex space_between align_items_center">
              <div class="slclear" @click="AFPdataClear">清空条件</div>
              <div class="slsubmit" @click="getApplyForProject">确定</div>
            </div>


          </div>

        </div>

      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { reactive, onMounted, computed } from "vue";
import { useRouter, useRoute } from 'vue-router'
import Api from "../../utils/api";
import Footer from "../../components/Footer.vue";
import Header from "../../components/Header.vue";
import { openAppFunction } from '../../common/js/share'
let data = reactive({
  filterShow: false,
  list: [],
  AFPdataString: '',
  AFPdata: {
    StartStatus: 0,
    typeId: 0,
    GoodType: 0,
    Keyword: '',
    PageSize: 10,
    PageIndex: 1,
  },
  loading: true,
  value: '',
  loadMoreStop: false,
  titleName:'物资申领',
});
const router = useRouter()
const onChange = (item) => {
  if(item.startStatus == 3){
    return
  }
  router.push({ path: '/applyDetails', query: { id: item.id } })
};
const StartStatusChange = (num) => {
  data.AFPdata.StartStatus = num
}

const typeIdChange = (num) => {
  data.AFPdata.typeId = num
}
const GoodTypeChange = (num) => {
  data.AFPdata.GoodType = num
}
const filterShowchange = (bel) => {
  if (bel) {
    data.AFPdataString = JSON.stringify(data.AFPdata)
  }
  data.filterShow = bel
}
const filterShowOff = () => {
  data.filterShow = false
  data.AFPdata = JSON.parse(data.AFPdataString)
}
const AFPdataClear = () => {
  data.AFPdata = {
    StartStatus: 0,
    typeId: 0,
    GoodType: 0,
    Keyword: '',
    PageSize: 10,
    PageIndex: 1,
  }
}
const submit = () => {
  let parmas = {}
  for (const key in data.AFPdata) {
    if (data.AFPdata[key]) {
      parmas[key] = data.AFPdata[key]
    }
  }
  console.log(parmas)

  Api.applyForProject(parmas).then((res) => {
    data.loading = false
    console.log(res);
    data.list.push(...res.data)
    filterShowchange(false)
  });
}
onMounted(() => {
  submit()
  shareFn()

});
// 分享详情
const shareFn = () => {
  const jsonString = {
    shareUrl: window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

const getApplyForProject = () => {
  data.AFPdata.PageIndex = 1
  data.list = []
  console.log(data.list, 99999999999)
  data.loading = true
  submit()
}
const loadMore = () => {
  data.AFPdata.PageIndex++
  submit()
}
const filteredTime = computed(() => (item) => {
  let day = 0
  if (item.startStatus == 1) {
    day = Math.ceil((new Date().getTime() - new Date(item.startTime).getTime()) / 86400000)
  }
  if (item.startStatus == 2) {
    day = Math.ceil((new Date(item.endTime).getTime() - new Date().getTime()) / 86400000)
  }

  return day

})

</script>
<style lang='less' scoped>
.jj_dialog {
  .slselect {
    padding: .4rem .32rem;
    ;
  }

  .slgroup {

    margin-bottom: .5067rem;

    .group_name {
      font-size: .3733rem;
      margin-bottom: .32rem;
    }
  }

  .slclear {
    width: 3.4rem;
    text-align: center;
    font-size: .3867rem;
    color: #92918F;
  }

  .slsubmit {
    width: 5.3867rem;
    height: 1.1333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E61D18;
    color: #fff;
    font-size: .3867rem;
    border-radius: .1067rem;
  }

  .diabtns {

    >div {
      width: 2.2133rem;
      height: .72rem;
      background-color: #f5f5f5;
      font-size: .3067rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: .1733rem;
      border-radius: .0533rem;
    }

    .active {
      background-color: #E61D18;
      color: #fff;
    }
  }
}

.materialsApply {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;
    padding-top: .2667rem;

    .list {
      padding: 0 .3333rem;
      height: calc(100vh - 4.85rem);
      // background-color:red;
      overflow-y: auto;

      >div {
        height: 2.96rem;
        font-size: .3867rem;
        border-bottom: .0133rem #ececec solid;
        padding-top: .2667rem;
        position: relative;

        &:last-child {
          border: none;
        }

        .lir {
          >div {
            margin-bottom: .1133rem;
          }
        }

        .grslbtn {
          height: .4133rem;
          width: 1.5733rem;
          background-color: #FFE200;
          color: #DE7F13;
          font-size: .28rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: .0533rem;
        }

        .white-space1 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .end {
          width: 1.5867rem;
          height: .4133rem;
          background: #ECECEC;
          border-radius: .0533rem;
          color: #78756A;
          font-size: .28rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .time {
          height: .4133rem;
          // width: auto;
          display: inline-block;
          border: .0133rem #E61D18 solid;
          font-size: .28rem;
          border-radius: .0533rem;

          .r {
            width: 1.6267rem;
            height: 100%;
            display: inline-block;
            color: #fff;
            background-color: #E61D18;
            border-radius: 0px .0533rem .0533rem 0px;
            text-align: center;
            line-height: .4133rem;
          }

          .d {
            min-width: 1.28rem;
            display: inline-block;
            text-align: center;
            color: #E61D18;
            font-weight: 600;
            line-height: .4133rem;
            padding: 0 .0667rem;
          }
        }

        .slbtn {
          width: 2.2667rem;
          height: .72rem;
          background-color: #E61D18;
          position: absolute;
          right: 0;
          bottom: .5067rem;
          border-radius: .0533rem;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .slbtn_end {
          background-color: #ACACAC !important;
          color: #fff !important;
        }

        span {
          color: #ACACAC;
          font-size: .28rem;
          margin-right: .5067rem;
        }

        img {
          width: 2.8267rem;
          height: 2.1733rem;
          border-radius: .1067rem;
          margin-right: .3333rem;
        }
      }

    }

    .apply_list_empty {
      text-align: center;
      padding-top: 15vh;

      img {
        width: 6.2933rem;
        height: 4.52rem;
      }

      .first_div {
        font-size: .4667rem;
        font-weight: 500;
        margin-top: .6667rem;
      }

      .last_div {
        font-size: .36rem;
        color: #ACACAC;
        margin-top: .2rem;

      }
    }

    .search {
      height: .92rem;
      font-size: .36rem;
      padding: 0 .3333rem;
      margin-bottom: .2667rem;

      .inpbox {
        width: 7.96rem;
        height: .92rem;
        background: #F5F5F5;
        border-radius: .1067rem;
        padding: 0 .3067rem;
      }

      .sea_icon {
        width: .44rem;
        margin-right: .16rem;
      }

      .van-cell {
        padding: 0;
        width: 5rem;
        height: .48rem;
        background-color: #F5F5F5;

        /deep/input {
          height: .48rem;
          padding-left: .16rem;
          background-color: #F5F5F5;
		  font-size: .36rem;
        }
      }

      .line {
        width: 1px;
        height: .36rem;
        border-right: .0133rem solid #D1D1D1;
        margin-left: .16rem;
        margin-right: .2rem;
      }

      .down {
        width: .2rem;
        margin-left: .16rem;
      }
    }
  }
}
</style>