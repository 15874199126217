<template>
  
  <div class="materialsApply">
    <div class="content" :style="data.detail.raise == 1?'padding-bottom: 5.2667rem;':''">
      <!-- 顶部 -->
      <Header :titleName="'捐赠项目'" :return="true" :borderShow="false" />
      <!-- 列表为空 -->
      <!-- <div class="apply_list_empty" v-if="!data.detail">
        <img src="@/assets/images/materialsApply/apply_list_empty.png" alt="">
        <div class="first_div">未找到</div>
        <div class="last_div">此捐赠项目不存在或已删除~</div>
      </div> -->
      <van-loading color="#888888" v-if="data.loading&&data.detail.raise == 1" vertical style="margin-top: 20vh;">加载中...</van-loading>
      <div v-else>
        <div>
          <img :src="data.detail.cover" alt="" style="width: 100%;object-fit: cover;margin-bottom: .5rem;">
          <div class="sj space_between align_items_center">
            <div style="background: linear-gradient( 180deg, #FFECEE 0%, #FFFFFF 100%);">
              <span style="color: #D80010;">{{ filteredAmount(data.detail.amount, 'amount') }}</span>
              <span>捐款金额(元)</span>
            </div>
            <div style="background: linear-gradient( 180deg, #ECF0FF 0%, #FFFFFF 100%);">
              <span style="color: #032AB4;">{{ filteredAmount(data.detail.bout, 'bout') }}</span>
              <span>捐款人次(次)</span>
            </div>
            <!-- <div style="background: linear-gradient( 180deg, #FEF6BD 0%, #FFFFFF 100%);">
              <span style="color: #DE7F13;">51,023,640</span>
              <span >捐款企业(家)</span>
            </div> -->
          </div>
          <div style="height: .2667rem;background-color: #f5f5f5;"></div>
        </div>
        
        <div class="card" style="padding-top: .3rem;">
          <div class="title">{{ data.detail.title }}</div>
          <div style="text-align: center;font-size: .3rem; color: #666;margin-bottom: .4rem;">公募编号:{{
            data.detail.public_offering_number }}</div>
          <div class="time flex justify_content_center">
            <div class="mr50">{{ data.detail.create_time }}</div>
            <div><img src="@/assets/images/donate/eye.png" alt="">{{ data.detail.read_num }}</div>
          </div>
          <div class="details_text" v-html="data.detail.content"></div>

        </div>

        <div class="jzlb" >
          <div class="jzlbList" >
            <van-loading color="#888888" v-if="data.loading1" vertical style="margin: 3rem 0;">加载中...</van-loading>
            <div  v-show="!data.loading1" class="space_between align_items_center" v-for="(item,index) in filteredDonateorderList(data.donateorderList)" :key="index" >
              <div style="width: 1.6667rem;text-align: center;">{{filteredDonateorderName(item.person)}}</div>
              <div style="width: 25%;text-align: center;">{{filteredDonateorderBriefl(item.briefl_name)}}</div>
              <div style="width: 28%;text-align: center;">捐<span class="cred">{{item.amount}}</span>元</div>
              <div style="width:1.7rem;text-align: right;">{{filteredDonateorderTime(item.pay_time)}}</div>
            </div>
             <div  v-show="!data.loading1" class="space_between align_items_center pagination" style="padding: 0 .8rem;font-size: .38rem;" v-if="data.donateorderAll">
              <div :class="data.pageIndex <= 1 ?'disa':''" @click="changPage('prev')">上一页</div>
              <div style="color: #666;">{{ data.pageIndex}} / {{ data.totalPage }}</div>
              <div :class="data.pageIndex >= data.totalPage ?'disa':''" @click="changPage('next')">下一页</div>
             </div>
          </div>
          <div  v-show="!data.loading1" style="font-size: .38rem;text-align: center;margin-bottom: .5rem;color: #777;" @click="data.donateorderAll = !data.donateorderAll">{{ data.donateorderAll?'收起':'查看更多' }}</div>
          <div class="footer_btn_div" @click="toDonate" v-if="data.detail.raise == 1">我要捐赠</div>
        </div>
        <!-- <div class="footer_btn" @click="toDonate" v-if="data.detail.raise == 1">
          <div>我要捐赠</div>
        </div> -->
      </div>





    </div>

  </div>
</template>

<script setup>

import { reactive, onMounted, computed } from "vue";
import Api from "../../utils/api";
import { useRouter, useRoute } from 'vue-router'
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import { openAppFunction } from '../../common/js/share'

const route = useRoute()
const router = useRouter()

let data = reactive({
  id: 0,
  detail: {},
  donateorderList:[],
  donateorderAll:false,
  loading: true,
  currentPage:1,
  totalPage:0,
  totalCount:0,
  loading1:false,
  pageIndex:1,
});

onMounted(() => {
  data.id = route.query.id
  Api.welfareInfo({ welfareId: data.id }).then(res => {
    if (res.status == 200) {
      data.detail = res.data
      data.detail.content = filterUnsafeHtml(res.data.content)
      data.detail.create_time = getYMDHMS(res.data.create_time)
      shareFn()
      //console.log(res.data)
    }
  })
  getDonateorderGetlist(1,15)
});

const changPage=(obj)=>{
	console.log(data.pageIndex,data.totalCount);
  if(obj == 'next'){
    if(data.pageIndex >= data.totalPage ){
      return
    }
    data.pageIndex++
  }else{
    if(data.pageIndex <= 1 ){
      return
    }
    data.pageIndex--
  }
	getDonateorderGetlist(data.pageIndex,15);
}

const shareFn = () => {
  const jsonString = {
    shareUrl:window.location.href,//分享链接
    shareCorver: data.detail.cover,
    shareTitle: '中国职工发展基金会',
    shareDesc: data.detail.title ,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

//获取捐赠详情
const getDonateorderGetlist = (pageIndex,pageSize) => {
	data.loading1 = true
  Api.getDonateorderGetlist({projectId:data.id,pageIndex:data.pageIndex,pageSize:pageSize}).then((res) => {

    if (res.status == 200) {
      data.donateorderList = res.data;
      let pageInf=JSON.parse(res.headers["x-pagination"]);
      data.totalPage=pageInf.totalPages;
      data.totalCount=pageInf.totalCount;
      console.log(data)
    }
    data.loading = false
    data.loading1 = false
  });
};

const toDonate = () => {
  router.push({ path: '/donate', query: { id: data.detail.id } })
}
const getYMDHMS = (timestamp) => {
  let time = new Date(timestamp * 1000)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()
  let hours = time.getHours()
  let minute = time.getMinutes()
  let second = time.getSeconds()

  // if (month < 10) { month = '0' + month }
  // if (date < 10) { date = '0' + date }
  if (hours < 10) { hours = '0' + hours }
  if (minute < 10) { minute = '0' + minute }
  if (second < 10) { second = '0' + second }
  return year + '年' + month + '月' + date + '日 ' + hours + ':' + minute + ':' + second
}

const filterUnsafeHtml = (str) => {
  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp': '&',
    'quot': '"'
  };
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,
    function (all, t) {
      return arrEntities[t];
    });
}

const filteredAmount = computed(() => (number, name) => {

  const numberStr = name == 'amount' ? Number(number).toFixed(2) : Number(number).toFixed().replace(/^0+/, '')

  // 使用正则表达式将每三个数字分隔
  const formatted = numberStr.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
  // 如果小数部分为00，则去除小数点和零
  if (number == 0) {
    return 0
  } else if (isNaN(numberStr)) {
    return 0
  } else {
    return formatted.replace(/,\d{2}$/, '');
  }

})
const filteredDonateorderList = computed(() => (list) => {
  if(data.donateorderAll){
    return list
  }else{
    return list.slice(0, 3)
  }
})
const filteredDonateorderName = computed(() => (name) => {
  if(!name){
    return '爱心人士'
  }
  if(name.length>2){
    return name[0] + '*' + name[name.length-1]
  }else{
    return name[0] + '*'
  }
})
const filteredDonateorderBriefl = computed(() => (name) => {
  return name.length>5?'*'+name.substring(name.length-5, name.length):name?name:'无'
})
const filteredDonateorderTime = computed(() => (dateTimeStamp) => {
  var result = ''
    var minute = 1000 * 60
    var hour = minute * 60
    var day = hour * 24
    var month = day * 30
    var now = new Date().getTime()
    var diffValue = now - dateTimeStamp*1000
    if (diffValue < 0) return
    var monthC = diffValue / month
    var weekC = diffValue / (7 * day)
    var dayC = diffValue / day
    var hourC = diffValue / hour
    var minC = diffValue / minute
    if (monthC >= 1) {
      result = "" + parseInt(monthC) + "月前"
    }
    else if (weekC>=1) {
      result = "" + parseInt(weekC) + "周前"
    }
    else if (dayC >= 1) {
      result = ""+ parseInt(dayC) + "天前"
    }
    else if (hourC >= 1){
      result = "" + parseInt(hourC) + "小时前"
    }
    else if (minC >= 1) {
      result = ""+ parseInt(minC) + "分钟前"
    } else {
      result = "刚刚"
    }
    return result
  
})



</script>

<style lang='less' scoped>


.materialsApply {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    
    background-color: #fff;
    .jzlb{
      width: 100%;
      height: auto;
      background-color: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);
      padding-bottom: .4rem;
      padding:.6rem 0 .2rem 0 ;
      .pagination{
        // color: #E61D18;
        margin-top: .5rem;
        .disa{
          color: #999;
        }
      }
     
      .jzlbList{
        font-size: .38rem;
        overflow-y: scroll;
        padding: 0 .6rem;
        margin-bottom: .2rem;
        >div{
          margin-bottom: .2667rem;
          // &:last-child{
          //   margin-bottom: 0;
          // }
        }
      }
    }
    .sj {
      padding: 0 .8rem;
      margin-bottom: .5rem;

      >div {
        display: flex;
        flex-direction: column;
        width: 4rem;
        height: 1.5333rem;
        padding: .3rem 0;
        border-radius: .1067rem;
        text-align: center;

        span {
          white-space: nowrap;

          &:first-child {
            font-size: .4rem;
            font-weight: 600;
            margin-bottom: .1rem;
            padding: 0 .3rem;
          }

          &:last-child {
            font-size: .28rem;
            padding: 0 .3rem;
          }
        }
      }
    }

    .apply_list_empty {
      text-align: center;
      padding-top: 15vh;

      img {
        width: 6.2933rem;
        height: 4.52rem;
      }

      .first_div {
        font-size: .4667rem;
        font-weight: 500;
        margin-top: .6667rem;
      }

      .last_div {
        font-size: .36rem;
        color: #ACACAC;
        margin-top: .2rem;

      }
    }

    .card {
      padding: .4rem .5067rem;
    }

    .footer_btn {
      height: 2.3333rem;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: #fff;
      box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);
      z-index: 2;
      
    }
    .footer_btn >div,.footer_btn_div {
        width: 9.3867rem;
        height: 1.1333rem;
        background: #E61D18;
        border-radius: .1067rem;
        margin: .2667rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .3867rem;
        color: #fff;
      }
    .details_text {
      /deep/img {
        max-width: 100% !important;
        display: block;
      }
      font-size: 14px;
      line-height: 26px;
    }

    .time {
      font-size: .3867rem;
      color: #999;
      margin-bottom: .6667rem;

      .mr50 {
        margin-right: .6667rem;
      }

      img {
        width: .4133rem;
        margin-right: .2133rem;
        vertical-align: text-top;
      }
    }

    .title {
      font-size: .56rem;
      font-weight: 600;
      margin-bottom: .1rem;
      text-align: center;
    }

    .top {
      height: 1.1333rem;
      width: 100%;
      font-size: .44rem;
      text-align: center;
      padding-top: .3333rem;
      box-sizing: border-box;
      position: relative;

      .return {
        width: 1.2rem;
        height: 1.1333rem;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: .4rem;
        display: flex;
        align-items: center;

        img {
          width: .2667rem;
        }
      }
    }



  }
}
</style>