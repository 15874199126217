<template>
  <div class="materialsApply">
    <div class="content"  id="content">
      <!-- 顶部 -->
      <Header :titleName="data.titleName" :return="data.donateReturn" :borderShow="true" />
      <!-- <div class="donateSearch" @click="donateSearch">
        <img style="width:.5rem;" src="@/assets/images/donate/search_icon.png" alt="">
      </div> -->
      <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>
      <div class="list" v-if="!data.loading">
        <div class="flex" v-for="(item, index) in data.projectList" :key="index" :class="index == 2 ? 'active' : ''">
          <img @click="toDonateDetails(item.id)" :src="item.cover" alt="">
          <div class="lir">
            <div class="tag">{{ index < 9 ? 0 + '' + (index + 1) : index + 1 }}</div>
                <div>
                  <div class="p1" @click="toDonateDetails(item.id)">{{ item.title }}</div>
                  <div style="font-size: .28rem; color: #888;display: none;"><span style="margin-right: .2rem;">{{
                      item.bout }}人捐款</span> 共{{ item.amount }}元</div>
                </div>

                <div class="space_between align_items_center">
                  <div>

                    <div class="grslbtn" style="display: none;">{{ item.menu_id == 283 ? '专项基金' : item.menu_id == 285
                      ? '品牌项目' : '公益活动' }} </div>
                  </div>

                  <div class="slbtn" v-if="item.raise==1" @click="toDonate(item.id)">献爱心</div>
                  <div class="slbtn" v-else @click="toDonateDetails(item.id)">献爱心</div>
                </div>
            </div>
          </div>
        </div>


      </div>
      <Footer v-if="!data.donateReturn" />
    </div>
</template>

<script setup>

import { reactive, onMounted } from "vue";
import Api from "../../utils/api";
import { useRouter, useRoute } from 'vue-router'
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import { openAppFunction } from '../../common/js/share'
const route = useRoute()
const router = useRouter()

let data = reactive({
  id: 0,
  detail: null,
  projectList: [],
  donateReturn: false,
  loading: true,
  titleName: '捐赠项目',
});

onMounted(() => {
  //获取项目列表
  getwelfareGetlist()
  if (sessionStorage.getItem('donateReturn')) {
    data.donateReturn = true
    sessionStorage.removeItem('donateReturn')
  }
  shareFn()

});

const shareFn = () => {
  const jsonString = {
    shareUrl: window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}


//跳转捐赠项目详情
const toDonateDetails = (id) => {
  let scrollTop = document.getElementById("content").scrollTop
  sessionStorage.setItem('donateListScrollTop', scrollTop)
  router.push({ path: '/donateDetails', query: { id: id } })
};

//我要捐赠页面
const toDonate = (id) => {
  let scrollTop = document.getElementById("content").scrollTop
  sessionStorage.setItem('donateListScrollTop', scrollTop)
  router.push({ path: '/donate', query: { id: id } })
}
//捐赠查询页面
const donateSearch = () => {
  router.push({ path: '/find' })
}

//获取捐赠项目列表
const getwelfareGetlist = () => {
  Api.welfareGetlist().then((res) => {
    data.loading = false
    if (res.status == 200) {
      data.projectList = res.data
      console.log(data.projectList)

      // 判断上一页是不是详情 是的话列表加载出来
      if (sessionStorage.getItem('donateListScrollTop')) {
        if (router.options.history.state.forward) {
          console.log(router.options.history.state.forward)
          if (router.options.history.state.forward.includes('/donate')) {
            setTimeout(() => {
              document.getElementById("content").scrollTop = sessionStorage.getItem('donateListScrollTop')
              sessionStorage.removeItem('donateListScrollTop')
            }, 50);
          }else{
            sessionStorage.removeItem('donateListScrollTop')
          }
        }

      }



    }
  });
};
const getYMDHMS = (timestamp) => {
  let time = new Date(timestamp * 1000)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()
  let hours = time.getHours()
  let minute = time.getMinutes()
  let second = time.getSeconds()

  // if (month < 10) { month = '0' + month }
  // if (date < 10) { date = '0' + date }
  if (hours < 10) { hours = '0' + hours }
  if (minute < 10) { minute = '0' + minute }
  if (second < 10) { second = '0' + second }
  return year + '年' + month + '月' + date + '日 ' + hours + ':' + minute + ':' + second
}

const filterUnsafeHtml = (str) => {
  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp': '&',
    'quot': '"'
  };
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,
    function (all, t) {
      return arrEntities[t];
    });
}
</script>

<style lang='less' scoped>
.materialsApply {
  display: flex;
  flex-flow: column;
  height: 100%;

  .donateSearch {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    font-size: .28rem;

    width: 2rem;
    padding-right: .4rem;
    height: 1.1333rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;
    position: relative;

    .list {
      padding: 0 .4rem;
      overflow-y: auto;
      overflow-x: hidden;

      >div {
        font-size: .3867rem;
        border-bottom: .0133rem #ececec solid;
        padding: .4rem 0;
        position: relative;

        &:last-child {
          border: none;
        }

        .lir {
          height: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 3.9rem);
          position: relative;
          background-color: #fff;

          .p1 {
            text-indent: 1rem;
            width: 4.8rem;
            margin-bottom: .1rem;
          }
        }


        .grslbtn {
          height: .4133rem;
          width: 1.5733rem;
          background-color: #FFE200;
          color: #DE7F13;
          font-size: .28rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: .0533rem;
        }



        .slbtn {
          width: 1.5333rem;
          height: .6rem;
          background-color: #E61D18;
          border-radius: .0533rem;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .3rem;
        }


        .tag {
          background-color: #D80010;
          width: .92rem;
          height: .4267rem;
          border-radius: .1067rem 0px .1067rem 0px;
          color: #fff;
          font-weight: 600;
          font-size: .2667rem;
          position: absolute;
          left: 0;
          top: .05rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 3.5rem;
          height: 2rem;
          border-radius: .1067rem;
          margin-right: .3333rem;
          object-fit: cover;
        }
      }

    }




  }
}
</style>